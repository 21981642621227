import React from "react"
import {Card, CardHeader, CardContent} from "@mui/material"

function ErrorPage({message}) {
	return (
		<Card>
			<CardHeader title="Error"></CardHeader>
			<CardContent>{message}</CardContent>
		</Card>
	)
}

function NotFound() {
	return <ErrorPage message="Page not found" />
}

export {ErrorPage, NotFound}
