const {createTheme} = require("@mui/material/styles")
const {createSpacing} = require("@mui/system")

const themeName = "InDebted"
const spacing = createSpacing(4)

// Use this tool for named colors, e.g. vulcanSlate
// http://chir.ag/projects/name-that-color/
const colors = {
	indebtedPrimary: "#474CDE",
	waterLeaf: "#A0E9E3",
	stratos: "#020452",
	green: "#388E3C",
	red: "#B00020",

	// slate
	vulcanSlate: "#12161F",
	slate: "#1B222F",

	// greyscale
	boulderGrey: "#7D7D7D",
	white: "#FFFFFF",
}

const palette = {
	primary: {main: colors.indebtedPrimary, contrastText: colors.white},
	secondary: {main: colors.slate, contrastText: colors.white},
}

const theme = createTheme({
	themeName,
	spacing,
	palette,
	components: {
		MuiButton: {
			defaultProps: {
				color: "primary",
				variant: "contained",
			},
		},
		MuiIconButton: {
			defaultProps: {
				color: "primary",
			},
		},
		MuiListItem: {
			defaultProps: {
				dense: true,
			},
		},
		MuiContainer: {
			styleOverrides: {
				root: {
					padding: spacing(3),
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					color: colors.boulderGrey,
					fontSize: "0.875rem",
				},
			},
		},
	},
})

module.exports = {theme, colors}
