import React, {useEffect, useState, useMemo, useCallback} from "react"
import {Link, useNavigate} from "react-router-dom"
import styled from "styled-components"
import {api} from "@indebted/api"
import {theme} from "@indebted/theme"
import {useNotification} from "@indebted/components/Notification"
import {IFrame} from "@indebted/components/IFrame"
import {useDebounce, useComputedState} from "@indebted/hooks"
import {
	Autocomplete,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from "@mui/material"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"

function Form(props) {
	const [component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		api.emailTemplate
			.new()
			.then((res) => {
				setComponent(
					<FormContent
						{...props}
						localesOptions={res.LocalesOptions}
						layoutOptions={res.Layouts}
						tags={res.Tags}
					/>,
				)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} />)
			})
	}, [props])

	return component
}

// eslint-disable-next-line complexity
function FormContent({title, initialState, LayoutComponent, disabled, localesOptions, layoutOptions, tags}) {
	const [form, setForm] = useState(initialState)
	const [archived, setArchived] = useState(initialState.Archived)
	const {notification} = useNotification()
	const navigate = useNavigate()
	const [forValidationPeriodOptions, setForValidationPeriodOptions] = useState(
		getValidationPeriodOptions(localesOptions, initialState.Locale),
	)

	const fromNamesInitialState = {
		Name: initialState.FromName ? [initialState.FromName] : [],
		Enable: false,
	}

	const [fromNames, setFromNames] = useState(fromNamesInitialState)

	const getLayoutById = useCallback(
		(layoutID) => {
			return layoutOptions.filter((x) => x.LayoutID === layoutID)[0]
		},
		[layoutOptions],
	)

	const availableLocaleOptionsForLayout = useMemo(() => {
		const selectedLayout = getLayoutById(form.LayoutID)

		const availableLocalesForLayout = selectedLayout ? selectedLayout.Locales : []

		return localesOptions.filter((x) => availableLocalesForLayout.includes(x.Name))
	}, [form.LayoutID, localesOptions, getLayoutById])

	const displayLayoutsDropdown = layoutOptions.length > 1

	const handleToggle = () => {
		const _api = api.emailTemplate
		const toggleFn = archived ? _api.restore : _api.archive
		toggleFn(initialState.ID)
			.then(() => {
				notification.success(archived ? "Template restored" : "Template archived")
				setArchived(!archived)
			})
			.catch((error) => notification.error(error.Message))
	}

	const previewOptions = {
		DisplayOriginatingCreditors: "Originating Creditors",
		DisplayCommunicationsDisclosure: "Communications Disclosure",
		DisplayWithPaymentPlanDiscount: "With PaymentPlan Discount",
		DisplayWithDiscount: "With Discount",
		DisplayDisputeLink: "Dispute Link",
		DisplayOptOutLink: "Opt-out Link",
	}
	const subjectMaxLength = 450

	const handleInput = (name) => ({target: {value}}) => {
		setForm({...form, [name]: value})
	}

	const handleLayoutOnChange = ({target: {value}}) => {
		const selectedLayout = getLayoutById(value)

		setForm((state) => ({
			...state,
			LayoutID: selectedLayout.LayoutID,
		}))

		if (!selectedLayout.Locales.includes(form.Locale)) {
			handleLocaleOnChange({target: {value: ""}})
		}
	}

	const handleLocaleOnChange = ({target: {value}}) => {
		const validationPeriodOptions = getValidationPeriodOptions(localesOptions, value)
		setForValidationPeriodOptions(validationPeriodOptions)

		const senders = getSendersFromLocaleOptions(localesOptions, value)
		setFromNames(senders)

		if (value !== "") {
			setForm((state) => ({
				...state,
				["Locale"]: value,
				["ForValidationPeriod"]: validationPeriodOptions.length === 1 ? validationPeriodOptions[0] : "",
				["FromName"]: senders.Name.length === 1 ? senders.Name[0] : [],
			}))
		} else {
			setForm((state) => ({
				...state,
				["Locale"]: value,
				["ForValidationPeriod"]: "",
				["FromName"]: [],
			}))

			setFromNames(fromNamesInitialState)
		}
	}

	const handleLayoutAttribute = (name) => ({target: {value}}) => {
		setForm({...form, LayoutAttributes: {...form.LayoutAttributes, [name]: value}})
	}

	const handleChecklist = (name, value) => () => {
		const a = form[name].slice(0)
		a.includes(value) ? a.splice(a.indexOf(value), 1) : a.push(value)
		setForm({...form, [name]: a})
	}

	const submit = (e) => {
		e.preventDefault()
		api.emailTemplate
			.create(form)
			.then(() => navigate("/templates"))
			.then(() => notification.success("Template created"))
			.catch((error) => notification.error(error.Message))
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={2}>
				<Card>
					<List>
						<ListSubheader>Preview Options</ListSubheader>
						{Object.keys(previewOptions).map((k) => (
							<ListItem key={k} onClick={handleChecklist("PreviewOptions", k)} button>
								<Checkbox
									value={k}
									checked={form.PreviewOptions.includes(k)}
									edge="start"
									tabIndex={-1}
								/>
								<ListItemText primary={previewOptions[k]} />
							</ListItem>
						))}
					</List>
				</Card>
				<SettingsCard>
					<List>
						<ListSubheader>Settings</ListSubheader>
						<ListItem dense={false} button component={Link} to={`duplicate`}>
							Duplicate
						</ListItem>
						<ListItem dense={false} button onClick={handleToggle}>
							{archived ? "Restore" : "Archive"}
						</ListItem>
					</List>
				</SettingsCard>
			</Grid>
			<Grid item xs={5}>
				<Card>
					<form onSubmit={submit}>
						<CardHeader title={title} />
						<CardContentFormControls>
							{displayLayoutsDropdown && (
								<FormControl required fullWidth={true}>
									<InputLabel variant="outlined">Layout</InputLabel>
									<Select
										label="Layout"
										value={form.LayoutID}
										onChange={handleLayoutOnChange}
										variant="outlined"
										disabled={disabled}
										native
									>
										<option value="" disabled />
										{layoutOptions.map(({LayoutName, LayoutID}) => (
											<option key={LayoutName} value={LayoutID}>
												{LayoutName}
											</option>
										))}
									</Select>
								</FormControl>
							)}
							<Grid container justifyContent="space-between" alignItems="center">
								<Grid item xs={true}>
									<FormControl required fullWidth={true}>
										<InputLabel variant="outlined">Locale</InputLabel>
										<Select
											label="Locale"
											value={form.Locale}
											onChange={handleLocaleOnChange}
											variant="outlined"
											disabled={disabled}
											native
										>
											<option value="" disabled />
											{availableLocaleOptionsForLayout.map(({Name}) => (
												<option key={Name} value={Name}>
													{Name}
												</option>
											))}
										</Select>
									</FormControl>
								</Grid>
								{forValidationPeriodOptions.length > 1 && (
									<ForValidationPeriod item>
										<FormControl>
											<FormLabel component="label" required>
												For validation period
											</FormLabel>
											<RadioGroup
												row
												name="forValidationPeriod"
												variant="outlined"
												value={form.ForValidationPeriod}
												onChange={handleInput("ForValidationPeriod")}
												disabled={disabled}
											>
												{forValidationPeriodOptions.map((option) => (
													<FormControlLabel
														key={option}
														value={option}
														control={<Radio required />}
														label={option}
														disabled={disabled}
													/>
												))}
											</RadioGroup>
										</FormControl>
									</ForValidationPeriod>
								)}
							</Grid>
							<FormControl required fullWidth={true}>
								<InputLabel variant="outlined">From</InputLabel>
								<Select
									label="From"
									value={form.FromName}
									onChange={handleInput("FromName")}
									variant="outlined"
									disabled={!fromNames.Enable}
									native
								>
									<option value="" disabled />
									{fromNames.Name.map((f) => (
										<option key={f} value={f}>
											{f}
										</option>
									))}
								</Select>
							</FormControl>

							<FormControl fullWidth={true}>
								<TextField
									label="Subject"
									value={form.Subject}
									onChange={handleInput("Subject")}
									inputProps={{maxLength: subjectMaxLength, "data-lpignore": "true"}}
									variant="outlined"
									disabled={disabled}
									required
								/>
								<Box display="flex" justifyContent="flex-end">
									<FormHelperText variant="outlined">
										{form.Subject.length} / {subjectMaxLength}
									</FormHelperText>
								</Box>
							</FormControl>

							<LayoutComponent
								form={form.LayoutAttributes}
								onChange={handleLayoutAttribute}
								disabled={disabled}
							/>

							<Autocomplete
								multiple
								options={tags}
								value={form.Tags || []}
								onChange={(_event, value) => setForm({...form, Tags: value})}
								disabled={disabled}
								filterSelectedOptions
								renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
							/>
						</CardContentFormControls>
						<CardActions>
							<Box display="flex" justifyContent="flex-end" width="100%">
								<Button type="submit" disabled={disabled}>
									Save
								</Button>
							</Box>
						</CardActions>
					</form>
				</Card>
			</Grid>
			<PreviewGrid item xs={5}>
				<PreviewCard>
					<CardHeader title="Preview" />
					<PreviewCardContent>
						<Preview form={form} />
					</PreviewCardContent>
				</PreviewCard>
			</PreviewGrid>
		</Grid>
	)
}

function Preview({form}) {
	const debouncedForm = useDebounce(form)

	const previewURL = useComputedState(() => {
		return api.emailTemplate.previewUrl(debouncedForm)
	}, [debouncedForm])

	if (!debouncedForm.Locale) {
		return <p>Select locale to start the preview</p>
	}

	return <PreviewIFrame src={previewURL} />
}

function getValidationPeriodOptions(localesOptions, selectedLocale) {
	let validationPeriodOptions = []
	if (selectedLocale != "") {
		validationPeriodOptions = localesOptions.find((option) => option.Name === selectedLocale)
			.ValidationPeriodOptions
	}
	return validationPeriodOptions
}

function getSendersFromLocaleOptions(localesOptions, selectedLocale) {
	let senders = []

	if (selectedLocale != "") {
		senders = localesOptions.find((option) => option.Name === selectedLocale).Senders
	}

	return senders
}

const PreviewGrid = styled(Grid)`
	display: flex;
	flex: 1;
`

const PreviewCard = styled(Card)`
	display: flex;
	flex: 1;
	flex-direction: column;
`

const SettingsCard = styled(Card)`
	margin-top: ${theme.spacing(3)};
`

const PreviewCardContent = styled(CardContent)`
	flex: 1;
`

const PreviewIFrame = styled(IFrame)`
	border: 0;
	width: 100%;
	height: 700px;
`

const CardContentFormControls = styled(CardContent)`
	& > .MuiFormControl-root:not(:last-child),
	.MuiGrid-container:not(:last-child) {
		margin-bottom: ${theme.spacing(5)};
	}
`

const ForValidationPeriod = styled(Grid)`
	padding-left: ${theme.spacing(5)};
`

export {Form}
