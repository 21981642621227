import React from "react"

import {Form} from "./Form"
import {getLayout} from "./Layouts"

function New() {
	const layout = getLayout("6ef56d05-15b7-4610-9c1e-6be6801c59cb")

	const initialState = {
		PreviewOptions: [],
		FromName: "",
		Subject: "",
		LayoutID: layout.id,
		LayoutAttributes: layout.defaultAttributes,
		Locale: "",
		ForValidationPeriod: "",
		Archived: false,
		ID: "",
		Tags: [],
	}

	return (
		<Form
			title="New Email Template"
			initialState={initialState}
			LayoutComponent={layout.component}
			disabled={false}
		/>
	)
}

export {New}
