import React, {useEffect, useState} from "react"
import {Link, useParams} from "react-router-dom"
import {api} from "@indebted/api"
import {useNotification} from "@indebted/components/Notification"
import {IFrame} from "@indebted/components/IFrame"
import styled from "styled-components"
import {useDebounce, useComputedState} from "@indebted/hooks"
import {
	Autocomplete,
	Card,
	CardContent,
	CardHeader,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	TextField,
	RadioGroup,
	Radio,
} from "@mui/material"
import {theme} from "@indebted/theme"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"

function View() {
	const {id} = useParams()
	const [component, setComponent] = useState(<LoadingPage />)

	useEffect(() => {
		Promise.all([api.smsTemplate.get(id), api.smsTemplate.new()])
			.then(([template, res]) => {
				const initialState = {
					PreviewOptions: [],
					LayoutID: template.LayoutID,
					Locale: template.Locale,
					Tags: template.Tags,
					Text: template.Text,
					ForValidationPeriod: template.ForValidationPeriod,
					Archived: template.Archived,
					ID: template.ID,
				}

				setComponent(
					<ViewContent
						title="View SMS Template"
						initialState={initialState}
						localesOptions={res.LocalesOptions}
						tags={res.Tags}
					/>,
				)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} />)
			})
	}, [id])

	return component
}

function ViewContent({title, initialState, localesOptions, tags}) {
	const [form, setForm] = useState(initialState)
	const [archived, setArchived] = useState(initialState.Archived)
	const {notification} = useNotification()
	const forValidationPeriodOptions = getValidationPeriodOptions(localesOptions, initialState.Locale)

	const handleToggle = () => {
		const _api = api.smsTemplate
		const toggleFn = archived ? _api.restore : _api.archive
		toggleFn(initialState.ID)
			.then(() => {
				notification.success(archived ? "Template restored" : "Template archived")
				setArchived(!archived)
			})
			.catch((error) => notification.error(error.Message))
	}

	const previewOptions = {
		OriginatingCreditors: "Originating Creditors",
	}

	const handleChecklist = (name, value) => () => {
		const a = form[name].slice(0)
		a.includes(value) ? a.splice(a.indexOf(value), 1) : a.push(value)
		setForm({...form, [name]: a})
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={2}>
				<Card>
					<List>
						<ListSubheader>Preview Options</ListSubheader>
						{Object.keys(previewOptions).map((k) => (
							<ListItem key={k} onClick={handleChecklist("PreviewOptions", k)} button>
								<Checkbox
									value={k}
									checked={form.PreviewOptions.includes(k)}
									edge="start"
									tabIndex={-1}
								/>
								<ListItemText primary={previewOptions[k]} />
							</ListItem>
						))}
					</List>
				</Card>
				<Settings archived={archived} handleToggle={handleToggle} />
			</Grid>
			<Grid item xs={5}>
				<Card>
					<CardHeader title={title} />
					<CardContentFormControls>
						<Grid container justifyContent="space-between" alignItems="center">
							<Grid item xs={true}>
								<FormControl required fullWidth={true}>
									<TextField label="Locale" value={form.Locale} variant="outlined" disabled={true} />
								</FormControl>
							</Grid>
							{forValidationPeriodOptions.length > 1 && (
								<ForValidationPeriod item>
									<FormControl>
										<FormLabel component="label" required>
											For validation period
										</FormLabel>
										<RadioGroup
											row
											name="forValidationPeriod"
											variant="outlined"
											value={form.ForValidationPeriod}
											disabled={true}
										>
											{forValidationPeriodOptions.map((option) => (
												<FormControlLabel
													key={option}
													value={option}
													control={<Radio required />}
													label={option}
													disabled={true}
												/>
											))}
										</RadioGroup>
									</FormControl>
								</ForValidationPeriod>
							)}
						</Grid>

						<FormControl fullWidth={true}>
							<TextField
								label="Text"
								value={form.Text}
								variant="outlined"
								disabled={true}
								rows="4"
								multiline
								required
							/>
						</FormControl>
					</CardContentFormControls>
					<TagForm initialState={initialState} tags={tags} />
				</Card>
			</Grid>
			<PreviewGrid item xs={5}>
				<PreviewCard>
					<CardHeader title="Preview" />
					<PreviewCardContent>
						<Preview form={form} />
					</PreviewCardContent>
				</PreviewCard>
			</PreviewGrid>
		</Grid>
	)
}

function Settings({archived, handleToggle}) {
	return (
		<SettingsCard>
			<List>
				<ListSubheader>Settings</ListSubheader>
				<ListItem dense={false} button component={Link} to={`duplicate`}>
					Duplicate
				</ListItem>
				<ListItem dense={false} button onClick={handleToggle}>
					{archived ? "Restore" : "Archive"}
				</ListItem>
			</List>
		</SettingsCard>
	)
}

function TagForm({initialState, tags}) {
	const [form, setForm] = useState(initialState)
	const {notification} = useNotification()

	const submit = (e) => {
		e.preventDefault()
		api.smsTemplate
			.tag(initialState.ID, form.Tags)
			.then(() => notification.success("Template tagged"))
			.catch((error) => notification.error(error.Message))
	}

	return (
		<form onSubmit={submit}>
			<CardContentFormControls>
				<Autocomplete
					multiple
					options={tags}
					value={form.Tags || []}
					onChange={(_event, value) => setForm({...form, Tags: value})}
					filterSelectedOptions
					renderInput={(params) => <TextField {...params} label="Tags" placeholder="Tags" />}
				/>
			</CardContentFormControls>
		</form>
	)
}
function Preview({form}) {
	const debouncedForm = useDebounce(form)

	const previewURL = useComputedState(() => {
		return api.smsTemplate.previewUrl(debouncedForm)
	}, [debouncedForm])

	if (!debouncedForm.Locale) {
		return <p>Select locale to start the preview</p>
	}

	return <PreviewIFrame src={previewURL} />
}

function getValidationPeriodOptions(localesOptions, selectedLocale) {
	let validationPeriodOptions = []
	if (selectedLocale != "") {
		validationPeriodOptions = localesOptions.find((option) => option.Name === selectedLocale)
			.ValidationPeriodOptions
	}
	return validationPeriodOptions
}

const PreviewGrid = styled(Grid)`
	display: flex;
	flex: 1;
`

const PreviewCard = styled(Card)`
	display: flex;
	flex: 1;
	flex-direction: column;
`

const SettingsCard = styled(Card)`
	margin-top: ${theme.spacing(3)};
`

const PreviewCardContent = styled(CardContent)`
	flex: 1;
`

const PreviewIFrame = styled(IFrame)`
	border: 0;
	width: 100%;
	height: 100%;
`

const CardContentFormControls = styled(CardContent)`
	& > .MuiFormControl-root:not(:last-child),
	.MuiGrid-container:not(:last-child) {
		margin-bottom: ${theme.spacing(5)};
	}
`

const ForValidationPeriod = styled(Grid)`
	padding-left: ${theme.spacing(5)};
`

export {View}
