import {ElevatorPitch, elevatorPitchDefaultAttributes} from "./ElevatorPitch"

function getLayout(id) {
	return layouts.find((layout) => layout.id == id)
}

const layouts = [
	{
		id: "6ef56d05-15b7-4610-9c1e-6be6801c59cb",
		defaultAttributes: elevatorPitchDefaultAttributes,
		component: ElevatorPitch,
	},
	{
		id: "5b253da3-75cb-4533-a32b-ac679cbae325",
		defaultAttributes: elevatorPitchDefaultAttributes,
		component: ElevatorPitch,
	},
	{
		id: "261d0b09-d943-43b1-a70b-fe0f2fe57f13",
		defaultAttributes: elevatorPitchDefaultAttributes,
		component: ElevatorPitch,
	},
	{
		id: "d6d9bc64-61c4-4997-bbc1-d76e9bb9bd34",
		defaultAttributes: elevatorPitchDefaultAttributes,
		component: ElevatorPitch,
	},
]

export {getLayout}
