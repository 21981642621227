import React from "react"
import styled from "styled-components"
import {Link} from "react-router-dom"
import {colors} from "@indebted/theme"
import TextFieldsIcon from "@mui/icons-material/TextFields"
import AddBusinessIcon from "@mui/icons-material/AddBusiness"
import HistoryEduIcon from "@mui/icons-material/HistoryEdu"
import PersonAddIcon from "@mui/icons-material/PersonAdd"
import {Container} from "@mui/material"

import {InDebted} from "./InDebted"

const ClientOnboardingUrl = process.env.API_URL + "/client-onboard/index"
const ClientPortalUsersUrl = process.env.API_URL + "/client-portal/users"
const NoaUrl = process.env.API_URL + "/noa/index"

const navSize = "70px"

function Layout({children}) {
	return (
		<>
			<Nav>
				<NavLink to="/">
					<InDebted variant="square" width="2rem" brandColor="white" />
				</NavLink>
				<NavLink to="/templates">
					<TemplateIcon />
				</NavLink>
				<NavLinkAnchor
					title="Client Onboarding Page"
					target="_blank"
					rel="noreferrer"
					href={ClientOnboardingUrl}
				>
					<ClientOnboardingIcon />
				</NavLinkAnchor>
				<NavLinkAnchor
					title="Client Portal Users Page"
					target="_blank"
					rel="noreferrer"
					href={ClientPortalUsersUrl}
				>
					<ClientPortalIcon />
				</NavLinkAnchor>
				<NavLinkAnchor title="NOA Page" target="_blank" rel="noreferrer" href={NoaUrl}>
					<NoaIcon />
				</NavLinkAnchor>
			</Nav>
			<Main>
				<Container maxWidth={false}>{children}</Container>
			</Main>
		</>
	)
}

const Main = styled.main`
	padding-left: ${navSize};
`

const Nav = styled.nav`
	background: ${colors.slate};
	height: 100%;
	position: fixed;
	width: ${navSize};
`

const NavLink = styled(Link)`
	align-items: center;
	background: ${colors.slate};
	display: flex;
	height: ${navSize};
	justify-content: center;
	transition: background 250ms ease-in-out;
	width: ${navSize};

	&:hover {
		background: ${colors.darkslate};
	}
`

const NavLinkAnchor = styled.a`
	align-items: center;
	background: ${colors.slate};
	display: flex;
	height: ${navSize};
	justify-content: center;
	transition: background 250ms ease-in-out;
	width: ${navSize};

	&:hover {
		background: ${colors.darkslate};
	}
`

const TemplateIcon = styled(TextFieldsIcon)`
	color: ${colors.white};
`

const ClientOnboardingIcon = styled(AddBusinessIcon)`
	color: ${colors.white};
`

const NoaIcon = styled(HistoryEduIcon)`
	color: ${colors.white};
`

const ClientPortalIcon = styled(PersonAddIcon)`
	color: ${colors.white};
`

export {Layout}
