import React from "react"
import {Box, FormControl, FormHelperText, TextField} from "@mui/material"

const elevatorPitchDefaultAttributes = {
	Header: "",
	Pitch: "",
	CTA: "",
}

function ElevatorPitch({form, onChange, disabled}) {
	const maxLengthMap = {
		Header: 60,
		Pitch: 1000,
		CTA: 30,
	}

	return (
		<>
			<FormControl fullWidth={true}>
				<TextField
					label="Header"
					value={form.Header}
					onChange={onChange("Header")}
					inputProps={{maxLength: maxLengthMap.Header, "data-lpignore": "true"}}
					variant="outlined"
					disabled={disabled}
					required
				/>
				<Box display="flex" justifyContent="flex-end">
					<FormHelperText variant="outlined">
						{form.Header.length} / {maxLengthMap.Header}
					</FormHelperText>
				</Box>
			</FormControl>

			<FormControl fullWidth={true}>
				<TextField
					label="Pitch"
					value={form.Pitch}
					onChange={onChange("Pitch")}
					inputProps={{maxLength: maxLengthMap.Pitch}}
					variant="outlined"
					disabled={disabled}
					rows="4"
					multiline
					required
				/>
				<Box display="flex" justifyContent="flex-end">
					<FormHelperText variant="outlined">
						{form.Pitch.length} / {maxLengthMap.Pitch}
					</FormHelperText>
				</Box>
			</FormControl>

			<FormControl fullWidth={true}>
				<TextField
					label="CTA"
					value={form.CTA}
					onChange={onChange("CTA")}
					inputProps={{maxLength: maxLengthMap.CTA, "data-lpignore": "true"}}
					variant="outlined"
					disabled={disabled}
					required
				/>
				<Box display="flex" justifyContent="flex-end">
					<FormHelperText variant="outlined">
						{form.CTA.length} / {maxLengthMap.CTA}
					</FormHelperText>
				</Box>
			</FormControl>
		</>
	)
}

export {ElevatorPitch, elevatorPitchDefaultAttributes}
