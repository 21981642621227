import React, {useCallback, useEffect, useState} from "react"
import {api} from "@indebted/api"
import {ErrorPage} from "@indebted/components/Error"
import {LoadingPage} from "@indebted/components/Loading"
import {Card, CardHeader, Grid, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material"
import {useNavigate, useLocation} from "react-router-dom"

import {FiltersForm} from "./FiltersForm"
import {Result} from "./Result"

function Search() {
	const navigate = useNavigate()
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)

	const [data, setData] = useState()
	const [error, setError] = useState()
	const [filters, setFilters] = useState({
		Channels: getChannels(urlParams),
		Locales: getLocales(urlParams),
		ValidationPeriodOptions: getValidationPeriodOptions(urlParams),
		Archived: urlParams.get("Archived") === "true",
	})

	useEffect(search, [navigate, filters])

	function search() {
		setData(null)
		api.template.search(filters).then(setData).catch(setError)
		navigate(`/templates?${new URLSearchParams(filters).toString()}`, {replace: true})
	}

	const handleFiltersChanged = useCallback((filters) => {
		setFilters(filters)
	}, [])

	if (error) return <ErrorPage message={error.Message} />
	if (!data) return <LoadingPage />

	return (
		<Grid container spacing={3}>
			<Grid item xs={2}>
				<FiltersForm
					availableFilters={data.Filters}
					initialFilters={filters}
					onFiltersChanged={handleFiltersChanged}
				/>
			</Grid>
			<Grid item xs={10}>
				<Card>
					<CardHeader title="Templates" />
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Name</TableCell>
								<TableCell></TableCell>
								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{data.Templates.map((template) => (
								<Result key={template.ID} template={template} onUpdate={search} />
							))}
						</TableBody>
					</Table>
				</Card>
			</Grid>
		</Grid>
	)
}

function getChannels(urlParams) {
	const channels = urlParams.get("Channels")
	return channels ? channels.split(",") : ["Email", "SMS"]
}

function getLocales(urlParams) {
	const locales = urlParams.get("Locales")
	return locales ? locales.split(",") : ["en-AU", "en-NZ", "en-CA", "en-US", "en-GB", "fr-CA", "es-US"]
}

function getValidationPeriodOptions(urlParams) {
	const validationPeriodOptions = urlParams.get("validationPeriodOptions")
	return validationPeriodOptions ? validationPeriodOptions.split(",") : ["yes", "no"]
}

export {Search}
