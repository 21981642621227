import React from "react"
import ReactDOM from "react-dom"
import {BrowserRouter} from "react-router-dom"
import {datadogLogs} from "@datadog/browser-logs"

import {App} from "./App"
import {config} from "./config"

datadogLogs.init(config.datadogAuth)

ReactDOM.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>,
	document.getElementById("root"),
)
