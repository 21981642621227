import React from "react"

import {Form} from "./Form"

function New() {
	const initialState = {
		PreviewOptions: [],
		LayoutID: "4263ccae-018d-5a80-80de-1735ec4597c7",
		Locale: "",
		Tags: [],
		Text: "",
		ForValidationPeriod: "",
		Archived: false,
		ID: "",
	}

	return <Form title="New SMS Template" initialState={initialState} disabled={false} />
}

export {New}
