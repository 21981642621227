import React from "react"
import {Routes, Route} from "react-router-dom"

import {Duplicate} from "./Duplicate"
import {New} from "./New"
import {View} from "./View"

export const EmailTemplate = () => (
	<Routes>
		<Route path="/:id/duplicate" element={<Duplicate />} />
		<Route path="/new" element={<New />} />
		<Route path="/:id" element={<View />} />
	</Routes>
)
