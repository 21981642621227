import React from "react"
// noinspection ES6CheckImport
import {useNavigate} from "react-router-dom"
import {Security} from "@okta/okta-react"
import {OktaAuth, toRelativeUrl} from "@okta/okta-auth-js"
import {theme} from "@indebted/theme"
import {Layout} from "@indebted/components/Layout"
import {NotificationProvider} from "@indebted/components/Notification"
import {CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material"

import {AppRoutes} from "./Routes"
import {config} from "./config"

const oktaAuth = new OktaAuth(config.oktaAuth)

export const App = () => {
	const navigate = useNavigate()
	const restoreOriginalUri = (_oktaAuth, originalUri) => {
		navigate(toRelativeUrl(originalUri || "/", window.location.origin))
	}

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<NotificationProvider>
					<Layout>
						<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
							<AppRoutes />
						</Security>
					</Layout>
				</NotificationProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	)
}
