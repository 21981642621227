import React from "react"
import {Routes, Route} from "react-router-dom"

import {Search} from "./Search"

export const Template = () => {
	return (
		<Routes>
			<Route path="/" element={<Search />} />
		</Routes>
	)
}
