import React, {useEffect, useState} from "react"
import {api} from "@indebted/api"
import {ErrorPage} from "@indebted/components/Error"
import {LoadingPage} from "@indebted/components/Loading"
import {useParams} from "react-router-dom"

import {Form} from "./Form"

function Duplicate() {
	const {id} = useParams()
	const [component, setComponent] = useState(<LoadingPage />)

	useEffect(() => {
		api.smsTemplate
			.get(id)
			.then((template) => {
				const initialState = {
					PreviewOptions: [],
					LayoutID: template.LayoutID,
					Locale: template.Locale,
					Tags: template.Tags,
					Text: template.Text,
					ForValidationPeriod: template.ForValidationPeriod,
					Archived: template.Archived,
					ID: template.ID,
				}
				setComponent(<Form title="Duplicate SMS Template" initialState={initialState} disabled={false} />)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} />)
			})
	}, [id])

	return component
}

export {Duplicate}
