import React, {useState, useEffect} from "react"
import {Card, Checkbox, List, ListItem, ListItemText, ListSubheader, Switch} from "@mui/material"

function FiltersForm({availableFilters, initialFilters, onFiltersChanged}) {
	const [filters, setFilters] = useState(initialFilters)

	useEffect(() => onFiltersChanged(filters), [filters, onFiltersChanged])

	const handleChecklist = (name, value) => () => {
		const a = filters[name].slice()
		a.includes(value) ? a.splice(a.indexOf(value), 1) : a.push(value)
		setFilters({...filters, [name]: a})
	}

	const handleSwitch = (name) => () => {
		setFilters({...filters, [name]: !filters[name]})
	}

	return (
		<Card>
			<List>
				<ListSubheader>Channels</ListSubheader>
				{availableFilters.Channels.map((c) => (
					<ListItem key={c} onClick={handleChecklist("Channels", c)} button>
						<Checkbox value={c} checked={filters.Channels.includes(c)} edge="start" tabIndex={-1} />
						<ListItemText primary={c} />
					</ListItem>
				))}
			</List>
			<List>
				<ListSubheader>Locales</ListSubheader>
				{availableFilters.Locales.map((l) => (
					<ListItem key={l} onClick={handleChecklist("Locales", l)} button>
						<Checkbox value={l} checked={filters.Locales.includes(l)} edge="start" tabIndex={-1} />
						<ListItemText primary={l} />
					</ListItem>
				))}
			</List>
			<List>
				<ListSubheader>For validation period</ListSubheader>
				{availableFilters.ValidationPeriodOptions.map((l) => (
					<ListItem key={l} onClick={handleChecklist("ValidationPeriodOptions", l)} button>
						<Checkbox
							value={l}
							checked={filters.ValidationPeriodOptions.includes(l)}
							edge="start"
							tabIndex={-1}
						/>
						<ListItemText primary={l} />
					</ListItem>
				))}
			</List>
			<List>
				<ListSubheader>Settings</ListSubheader>
				<ListItem onClick={handleSwitch("Archived")} button>
					<ListItemText primary="Archived" />
					<Switch name="Archived" checked={filters.Archived} edge="end" tabIndex={-1} />
				</ListItem>
			</List>
		</Card>
	)
}

export {FiltersForm}
