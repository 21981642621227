import React, {useRef, useEffect} from "react"

function IFrame({className, src}) {
	const iframe = useRef()
	useEffect(() => {
		iframe.current.contentWindow.location.replace(src)
	}, [iframe, src])

	return <iframe className={className} ref={iframe} />
}

export {IFrame}
