class API {
	constructor() {
		this.emailTemplate = new EmailTemplate()
		this.smsTemplate = new SMSTemplate()
		this.template = new Template()
	}
}

class EmailTemplate {
	create(emailTemplate) {
		return post("/email-templates", emailTemplate)
	}

	previewUrl(emailTemplate) {
		const q = new URLSearchParams()
		q.append("Subject", emailTemplate.Subject)
		q.append("LayoutID", emailTemplate.LayoutID)
		q.append("LayoutAttributes", JSON.stringify(emailTemplate.LayoutAttributes))
		q.append("Locale", emailTemplate.Locale)
		q.append("OriginatingCreditors", emailTemplate.PreviewOptions.includes("DisplayOriginatingCreditors"))
		q.append("CommunicationsDisclosure", emailTemplate.PreviewOptions.includes("DisplayCommunicationsDisclosure"))
		q.append("DisplayWithDiscount", emailTemplate.PreviewOptions.includes("DisplayWithDiscount"))
		q.append(
			"DisplayWithPaymentPlanDiscount",
			emailTemplate.PreviewOptions.includes("DisplayWithPaymentPlanDiscount"),
		)
		q.append("DisplayDisputeLink", emailTemplate.PreviewOptions.includes("DisplayDisputeLink"))
		q.append("DisplayOptOutLink", emailTemplate.PreviewOptions.includes("DisplayOptOutLink"))
		return url(`/email-templates/preview?${q.toString()}`)
	}

	get(id) {
		return get(`/email-templates/${id}`)
	}

	archive(id) {
		return put(`/email-templates/${id}/archive`)
	}

	restore(id) {
		return put(`/email-templates/${id}/restore`)
	}

	new() {
		return get(`/email-templates`)
	}

	tag(id, tags) {
		return put(`/email-templates/${id}/tags`, {tags})
	}
}

class SMSTemplate {
	create(smsTemplate) {
		return post("/sms-templates", smsTemplate)
	}

	previewUrl(smsTemplate) {
		const q = new URLSearchParams()
		q.append("LayoutID", smsTemplate.LayoutID)
		q.append("Locale", smsTemplate.Locale)
		q.append("Text", smsTemplate.Text)
		q.append("OriginatingCreditors", smsTemplate.PreviewOptions.includes("OriginatingCreditors"))
		q.append("CommunicationsDisclosure", smsTemplate.PreviewOptions.includes("CommunicationsDisclosure"))
		return url(`/sms-templates/preview?${q.toString()}`)
	}

	get(id) {
		return get(`/sms-templates/${id}`)
	}

	archive(id) {
		return put(`/sms-templates/${id}/archive`)
	}

	restore(id) {
		return put(`/sms-templates/${id}/restore`)
	}

	new() {
		return get(`/sms-templates`)
	}

	tag(id, tags) {
		return put(`/sms-templates/${id}/tags`, {tags})
	}
}

class Template {
	search(filters) {
		return get(`/templates?${new URLSearchParams(filters).toString()}`)
	}
}

function get(endpoint, options = {}) {
	return req(endpoint, {...options, method: "GET"})
}

function post(endpoint, body, options = {}) {
	return req(endpoint, {...options, method: "POST", body: JSON.stringify(body)})
}

function put(endpoint, body, options = {}) {
	return req(endpoint, {...options, method: "PUT", body: JSON.stringify(body)})
}

function req(endpoint, options) {
	return new Promise((resolve, reject) => {
		fetch(url(endpoint), options)
			.then((res) => {
				const contentType = res.headers.get("Content-Type")
				const isJSON = contentType && contentType.includes("application/json")
				const promise = isJSON ? res.json() : res.text()
				promise.then((body) => (res.status >= 400 ? reject(apiError(body)) : resolve(body)))
			})
			.catch(() => {
				reject(apiError("Network error - have you enabled your VPN?"))
			})
	})
}

function url(endpoint) {
	return process.env.API_URL + endpoint
}

function apiError(error) {
	const unknownError = {Message: "Unknown error"}
	if (!error) return unknownError
	if (typeof error == "string") return {Message: error}
	if (error.message) return {Message: error.message}
	if (error.Message) return error
	return unknownError
}

const api = new API()
module.exports = {api}
