const {useEffect, useState} = require("react")

const useDebounce = (value, delay = 500) => {
	const [state, setState] = useState(value)
	useEffect(() => {
		const timeout = setTimeout(setState, delay, value)
		return () => clearTimeout(timeout)
	}, [value, delay])
	return state
}

const useComputedState = (fn, deps) => {
	const [state, setState] = useState(fn(deps))
	useEffect(() => setState(fn(deps)), deps) // eslint-disable-line react-hooks/exhaustive-deps
	return state
}

module.exports = {useDebounce, useComputedState}
