import React from "react"
import {colors} from "@indebted/theme"

const variants = {
	square: Square,
}

function InDebted({variant, width = "100%", brandColor = colors.waterLeaf, textColor = colors.stratos}) {
	const Variant = variants[variant]
	return <Variant width={width} brandColor={brandColor} textColor={textColor} />
}

function Square({width, brandColor, textColor}) {
	return (
		<svg width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 160" fill="none">
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<path fill={brandColor} d="M0,80C0,13.6,13.6,0,80,0s80,13.6,80,80-13.6,80-80,80S0,146.4,0,80" />
					<path
						fill={textColor}
						d="M85.16,130l-31.72-.1A2.51,2.51,0,0,1,51,127.39l0-7.2a2.51,2.51,0,0,1,2.51-2.49l31.68.1a48.93,48.93,0,0,0,19.55-5c12.08-6.19,18.21-17.2,18.21-32.74s-6.13-26.55-18.21-32.74a49,49,0,0,0-19.56-5l-43.9-.1a2.5,2.5,0,0,1-2.49-2.51v-7.2A2.51,2.51,0,0,1,41.28,30l43.9.1a60.75,60.75,0,0,1,24.64,6.13c11.54,5.77,25.29,18.06,25.29,43.82s-13.75,38.06-25.29,43.82A60.75,60.75,0,0,1,85.16,130Z"
					/>
					<rect fill={textColor} x="28.89" y="51.89" width="50.82" height="12.2" rx="2.5" />
					<rect fill={textColor} x="33.97" y="73.84" width="50.82" height="12.2" rx="2.5" />
					<rect fill={textColor} x="39.05" y="95.79" width="50.82" height="12.2" rx="2.5" />
				</g>
			</g>
		</svg>
	)
}

export {InDebted}
