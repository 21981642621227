import React from "react"
import styled from "styled-components"
import {Snackbar, SnackbarContent} from "@mui/material"
import {colors} from "@indebted/theme"

const Ctx = React.createContext()
const useNotification = () => React.useContext(Ctx)

function NotificationProvider({children}) {
	const [state, setState] = React.useState({
		open: false,
	})

	const notification = {
		success: (message) => {
			setState({...state, open: true, severity: "success", message: message})
		},
		error: (message) => {
			setState({...state, open: true, severity: "error", message: message})
		},
	}

	const handleClose = () => {
		setState({...state, open: false})
	}

	const origin = {
		vertical: "top",
		horizontal: "center",
	}

	return (
		<Ctx.Provider value={{notification}}>
			{children}
			<Snackbar anchorOrigin={origin} open={state.open} autoHideDuration={2500} onClose={handleClose}>
				<StyledSnackbarContent message={state.message} severity={state.severity} />
			</Snackbar>
		</Ctx.Provider>
	)
}

const colorMap = {
	success: colors.green,
	error: colors.red,
}

const StyledSnackbarContent = styled(SnackbarContent)`
	background: ${({severity}) => colorMap[severity]};
`

export {NotificationProvider, useNotification}
