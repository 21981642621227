import React from "react"
import {Routes, Route} from "react-router-dom"
import {LoginCallback} from "@okta/okta-react"
import {NotFound} from "@indebted/components/Error"
import {Navigate} from "react-router"

import {EmailTemplate} from "./views/EmailTemplate"
import {Template} from "./views/Template"
import {SMSTemplate} from "./views/SMSTemplate"
import {RequiredAuth} from "./RequiredAuth"

export const AppRoutes = () => {
	return (
		<Routes>
			<Route path="*" element={<NotFound />} />
			<Route
				path="/login/callback"
				element={<LoginCallback loadingElement={<h1 id="loading-icon">Loading...</h1>} />}
			/>
			<Route path="/" element={<RequiredAuth />}>
				<Route path="" element={<Navigate to="/templates" />} />
				<Route path="/email-templates/*" element={<EmailTemplate />} />
				<Route path="/sms-templates/*" element={<SMSTemplate />} />
				<Route path="/templates/*" element={<Template />} />
			</Route>
		</Routes>
	)
}
