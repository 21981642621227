import React, {useState} from "react"
import {Link} from "react-router-dom"
import styled, {css} from "styled-components"
import {api} from "@indebted/api"
import {colors} from "@indebted/theme"
import EmailIcon from "@mui/icons-material/Email"
import SettingsIcon from "@mui/icons-material/Settings"
import SmsIcon from "@mui/icons-material/Sms"
import {useNotification} from "@indebted/components/Notification"
import {Chip, IconButton, Menu, MenuItem, TableCell, TableRow} from "@mui/material"

function Result({template, onUpdate}) {
	const [anchorEl, setAnchorEl] = useState()
	const {notification} = useNotification()

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleToggle = () => {
		handleClose()
		const _api = channels[template.Channel].api
		const toggleFn = template.Archived ? _api.restore : _api.archive
		toggleFn(template.ID)
			.then(() => onUpdate())
			.then(() => notification.success(template.Archived ? "Template restored" : "Template archived"))
			.catch((error) => notification.error(error.Message))
	}

	const channels = {
		Email: {
			api: api.emailTemplate,
			basePath: "email-templates",
			icon: <StyledEmailIcon />,
		},
		SMS: {
			api: api.smsTemplate,
			basePath: "sms-templates",
			icon: <StyledSmsIcon />,
		},
	}

	const templatePath = `/${channels[template.Channel].basePath}/${template.ID}`

	return (
		<TableRow hover={true}>
			<ExpandedTableCell>
				{channels[template.Channel].icon}

				{template.Name}
			</ExpandedTableCell>
			<TableCell>
				{template.ForValidationPeriod == "yes" && (
					<Chip variant="outlined" size="small" color="primary" label="For validation period" />
				)}
			</TableCell>
			<ActionsTableCell>
				<IconButton onClick={handleClick} size="large">
					<SettingsIcon />
				</IconButton>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					anchorOrigin={{vertical: "bottom", horizontal: "right"}}
					transformOrigin={{vertical: "top", horizontal: "right"}}
					onClose={handleClose}
				>
					<li>
						<MenuItem component={Link} to={templatePath} onClick={handleClose}>
							View
						</MenuItem>
					</li>
					<li>
						<MenuItem component={Link} to={`${templatePath}/duplicate`} onClick={handleClose}>
							Duplicate
						</MenuItem>
					</li>
					<MenuItem onClick={handleToggle}>{template.Archived ? "Restore" : "Archive"}</MenuItem>
				</Menu>
			</ActionsTableCell>
		</TableRow>
	)
}

const ExpandedTableCell = styled(TableCell)`
	width: 100%;
`

const ActionsTableCell = styled(TableCell)`
	padding: 0;
`

const iconCss = css`
	font-size: 1.25rem;
	margin-right: 0.5rem;
	vertical-align: middle;
`

const StyledEmailIcon = styled(EmailIcon)`
	${iconCss}
	color: ${colors.indebtedPrimary};
`

const StyledSmsIcon = styled(SmsIcon)`
	${iconCss}
	color: ${colors.green};
`

export {Result}
